import $http from "../util/$http";
import {API_URL} from "@/config/config";

const holidaysLink = (params) => `${API_URL}business/${params.businessId}/holidays/`;

export const getHolidayData = async (params) => (
  await $http.get(holidaysLink(params) + params.id)
).data;
export const getHolidaysOptionsList = async () => (
  await $http.get('/data/options.json')
).data;
export const createHoliday = async (params) => (
  await $http.post(holidaysLink(params), params)
).data;
export const updateHoliday = async (params) => (
  await $http.post(holidaysLink(params) + params.id, params)
).data;
export const deleteHoliday = async (params) => (
  await $http.post(holidaysLink(params) + params.id + '/delete')
).data;

export const getHolidaysList = async (params) => (
  await $http.get(holidaysLink(params), {params})
).data;

export const filterHolidaysList = async (params) => (
  await $http.get(holidaysLink(params) + 'filter', {params})
).data;

export const syncHoliday = async (params, data) => (
  await $http.post(`${API_URL}business/${params.businessId}/items/` + params.itemId + '/holidays/sync', data)
).data;
