import {sidebarLinks} from "./sidebarLinks";
import * as _ from 'lodash';

// Business owner
const userClaims = [
  'Dashboard',
  'Menu',
  'Branches',
  'Menus',
  'Holidays',
  'Categories',
  'Orders',
  'Reservations',
  'Services',
  'Floors',
  'Tables',
  'Invoices',
  'Settings',
  'Users',
  'Audits',
  'Permissions'
]



export const userSidebarLinks = (userData) => {
  return _.filter(sidebarLinks, l =>  userClaims.indexOf(l.key) !== -1);
}
