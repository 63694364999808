import $http from "../util/$http";
import {API_URL} from "@/config/config";

const directory = 'items/';

const itemsLink = (params) => `${API_URL}business/${params.businessId}/items/`;

export const getItemData = async (params, id) => (await $http.get(itemsLink(params) + id)).data;
export const getItemsList = async (params) => (await $http.get(itemsLink(params), {params})).data;
export const createItem = async (data) => (await $http.post(itemsLink(data), data)).data;
export const updateItem = async (data) => (await $http.post(itemsLink(data) + data.id, data)).data;
export const deleteItem = async (params,id) => (await $http.post(itemsLink(params) + id+ '/delete')).data;
export const sortItems = async (data) => (await $http.post(itemsLink(data) + 'sort', data)).data;

export const searchItems = async (params) => (await $http.get(itemsLink(params)+'search', {params})).data;
