<template>
  <div v-if="chalet">
    <div class="form-group row">
      <span class="d-flex align-items-center col-sm-4 pl-2 pr-2 bg-white border-solid">Amount available</span>
      <b-input v-model="chalet.amount" type="number"
               placeholder="Bedrooms" class="border-radius-0  col-sm-8"/>
    </div>

    <div class="form-group row">
      <span class="d-flex align-items-center col-sm-4 pl-2 pr-2 bg-white border-solid">Bedrooms</span>
      <b-input v-model="chalet.bedrooms" type="number"
               placeholder="Bedrooms" class="border-radius-0  col-sm-8"/>
    </div>

    <div class="form-group row">
      <span class="d-flex align-items-center col-sm-4 pl-2 pr-2 bg-white border-solid">Frontage</span>
      <b-select v-model="chalet.frontage" :options="FrontageOptions()" type="number"
                placeholder="Frontage" class="border-radius-0  col-sm-8"/>
    </div>

    <div class="form-group row">
      <span class="d-flex align-items-center col-sm-4 pl-2 pr-2 bg-white border-solid">Address</span>
      <b-input v-model="chalet.address[$i18n.locale]" type="text"
               placeholder="Address" class="border-radius-0 col-sm-8"/>
    </div>

    <div class="form-group row">
      <span class="d-flex align-items-center col-sm-4 pl-2 pr-2 bg-white border-solid">Insurance</span>
      <b-input v-model="chalet.insurance" type="number"
               placeholder="Insurance" class="border-radius-0  col-sm-6"/>
      <span class=" align-items-center  small pl-2 pr-2 col-sm-2 bg-white border-solid">KWD</span>
    </div>

    <div class="form-group d-flex">
      <b-input v-model="chalet.latitude" type="number"
               placeholder="Latitude" class="border-radius-0 col-sm-6"/>
      <b-input v-model="chalet.longitude" type="number"
               placeholder="Longitude" class="border-radius-0 col-sm-6"/>
    </div>

    <div class="form-group row">
      <span class="d-flex align-items-center col-sm-4 pl-2 pr-2 bg-white border-solid">Owner Id</span>
      <b-input v-model="chalet.owner_id" type="number"
               placeholder="Owner Id" class="border-radius-0  col-sm-8"/>
    </div>

  </div>

</template>
<script>
import {ChaletModel, FrontageOptions} from "@/pages/Menu/Items/Chalet";

export default {
  name: "Chalet",
  methods: {
    FrontageOptions() {
      return FrontageOptions
    }
  },
  props: {
    model: null
  },
  data() {
    return {
      chalet: JSON.parse(JSON.stringify(ChaletModel)),
    }
  },
  mounted() {
    if (!this.model) {
      this.chalet = JSON.parse(JSON.stringify(ChaletModel));
      return;
    }
    Object.keys(this.model).forEach(key => {
      if (key === 'address' ) {
        this.chalet.address.en = this.model.address?.en ?? "";
        this.chalet.address.ar = this.model.address?.ar ?? "";
      }
      else
        this.chalet[key] = this.model[key]
    })
  },
  watch: {
    chalet: {
      deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal)
          this.$emit('input', newVal)
      }
    },
  }
}

</script>

<style scoped>

</style>
