<template>
  <div>
    <DateRangePicker ref="picker"
                     :date-range="model"
                     v-model="model"
                     :ranges="false"
                     @update="updateValues"
                     class="w-100 "
                     :min-date="minDate"
                     :auto-apply="true"
                     :picker-options="{}">
      <template v-slot:input="picker" >
        <div class="form-control m-0" v-if="picker.startDate && picker.endDate">
          {{ $t("From") }}: <b class="mr-3">{{ picker.startDate  | moment("DD-MM-YYYY") }}</b>
          {{ $t("To") }}: <b>{{ picker.endDate  | moment("DD-MM-YYYY") }}</b>
        </div>
      </template>
    </DateRangePicker>
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import {__DateOptions} from "@/util/dates";
import moment from 'moment';

export default {
  name: "MyDateRangePicker",
  props:{
    modelData:{
      type: Object,
      default: () => ({...__DateOptions})
    },
    minDate:{
      type: Date
    }
  },
  components: {
    DateRangePicker
  },
  data() {
    return {
      model:__DateOptions
    }
  },
  mounted(){
    this.model = this.modelData;
  },
  methods: {
    updateValues(values) {
      values.startDate = moment(values.startDate).format('YYYY-MM-DDT14:00');
      values.endDate = moment(values.endDate).format('YYYY-MM-DDT14:00');
      this.$emit('input', values)
    }
  },
  watch:{
    modelData:{
      deep:true,
      immediate: true,
      handler(value){
        if(value !== null)
          this.model = value;
      }
    }
  }
}
</script>

<style>
</style>
