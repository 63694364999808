import DiscountTypes from "../../config/DiscountTypes";
import moment from 'moment';

const DATE_FORMAT = "YYYY-MM-DDT00:00";

export default class {
  locales = [];
  amount = 0;
  type = DiscountTypes.value;
  from = null;
  to = null;

  constructor(locale) {
    this.locales.push(locale);

    this.from = moment().startOf('day').format(DATE_FORMAT);
    this.to = moment().startOf('day').add(30, 'days').format(DATE_FORMAT);
  }
}
