import $http from "../util/$http";
import {resetUser} from "@/util/authentication";
import store from "@/store"
import {API_URL} from "@/config/config";
import {formatUsersOptions} from "@/util/formatters";

// Business Users
const usersLink = (params) => `${API_URL}business/${params.businessId}/users/`;
export const getUserData = async (data, id) => (await $http.get(usersLink(data) + id)).data;
export const createUser = async (data) => (await $http.post(usersLink(data), data)).data;
export const searchUser = async (data) => (await $http.post(usersLink(data) + `search`, data)).data;
export const getUsersList = async (params) => (await $http.get(usersLink(params), {params})).data;
export const getUsersOptionsList = async (params) => formatUsersOptions((await getUsersList(params)).data);
export const updateUser = async (data) => (await $http.post(usersLink(data) + data.id, data)).data;
export const deleteUser = async (data) => (await $http.post(usersLink(data) + data.id + '/delete')).data;

// Auth Users
export const getCurrentUser = async () => (await $http.get(API_URL + 'users/info')).data;
export const loginUser = async (data) => (await $http.post(API_URL + 'auth/login', data)).data;
export const registerUser = async (data) => (await $http.post(API_URL + 'auth/register', data)).data;
export const sendCode = async (data) => (await $http.post(API_URL + 'auth/send-code', data)).data;
export const validateEmail = async (data) => (await $http.post(API_URL + 'auth/validate-code', data)).data;
export const logoutUser = async () => await resetUser(store);

// User Settings
const settingsLink = (data) => `${API_URL}users/${data.userId}/settings/`;
export const getUserSetting = async (data) => (await $http.get(settingsLink(data))).data;
export const setUserSetting = async (data) => (await $http.post(settingsLink(data) + 'set', {settings: data.data}));
