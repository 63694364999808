export const sidebarLinks = [
  {
    name: 'Dashboard',
    key: 'Dashboard',
    icon: 'nc-icon nc-bank',
    path: '/admin/overview'
  },
  {
    name: 'Chalets Listing',
    key: 'Menu',
    icon: 'fas fa-list',
    path: "/menu",
  },
  {
    name: 'Hotels',
    key: 'Hotels',
    icon: 'fas fa-hotel',
    path: "/hotels/list",
  },
  {
    name: 'Business',
    key: 'Business',
    icon: 'fas fa-utensils',
    path: "/business/list",
  },
  {
    name: 'Branches',
    key: 'Branches',
    icon: 'fas fa-utensils',
    path: "/branches/list",
  },
  {
    name: 'Menus',
    key: 'Menus',
    icon: 'fas fa-utensils',
    path: "/menus/list",
  },
  {
    name: 'Holidays',
    key: 'Holidays',
    icon: 'fas fa-utensils',
    path: "/holidays/list",
  },
  {
    name: 'Categories',
    key: 'Categories',
    icon: 'fas fa-utensils',
    path: "/categories/list",
  },
  {
    name: 'Orders',
    key: 'Orders',
    icon: 'fas fa-hand-holding-usd',
    path: "/orders/list",
  },
  {
    name: 'Reservations',
    key: 'Reservations',
    icon: 'fas fa-hand-holding-usd',
    path: "/reservations/list",
  },
  {
    name: 'Financial Reports',
    key: 'Invoices',
    icon: 'fas fa-hand-holding-usd',
    path: "/invoices/list",
  },
  {
    name: 'Services',
    key: 'Services',
    icon: 'fas fa-hands-helping',
    path: "/services/list",
  },
  {
    name: 'Events',
    key: 'Events',
    icon: 'fas fa-calendar-alt',
    path: "/events/list",
  },
  {
    name: 'Users',
    key: 'Users',
    icon: 'fas fa-users',
    path: "/users/list",
  },
  {
    name: 'Floors',
    key: 'Floors',
    icon: 'fas fa-users',
    path: "/floors",
  },
  {
    name: 'Settings',
    key: 'Settings',
    icon: 'fas fa-cog',
    path: "/settings",
  },
  {
    name: 'Audits',
    key: 'Audits',
    icon: 'fas fa-pen',
    path: "/audits",
  },
  {
    name: 'Permissions',
    key: 'Permissions',
    icon: 'fas fa-cog',
    path: "/permissions",
  }
]
