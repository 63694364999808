
export const models = {
  name:null,
  email:null,
  password:null,
  phone_number:null,
  phone:null,
  password_confirmation:null,
}
export const options = {
}

export const fields = [
  {
    label:'Name',
    name:'name',
    placeholder:'Enter the name',
    type:'text',
    value:'',
    rules:'required',
    validation_messages:{
      required: 'This field is required'
    }
  },
  {
    label:'Email',
    name:'email',
    placeholder:'Enter the email',
    type:'text',
    value:'',
    rules:'email',
    validation_messages:{
      required: 'This field is required'
    }
  },
  {
    label:'Phone',
    name:'phone_number',
    placeholder:'Enter the Phone number',
    type:'phone',
    value:'',
    rules:'phone|required',
    validation_messages:{
      required: 'This field is required',
      phone: 'Not valid phone number'
    }
  },
  {
    label:'Password',
    name:'password',
    placeholder:'Enter the password',
    type:'password',
    value:'',
    rules:'',
    validation_messages:{
      required: 'This field is required'
    }
  }
];
