<template>
  <div>

    <div v-for="(discount, index) in discounts">

      <template v-if="discount.id">
        <div class="form-group d-flex">

          <span class="d-flex align-items-center small pl-2 pr-2 bg-white border-solid">Discount</span>

          <b-input v-model="discounts[index].amount"
                   @change=""
                   class="border-radius-0"
                   placeholder="Amount"/>
          <b-form-select v-model="discount.type" variant="warning"
                         class=" no-margin border-radius-0 px-2 border-solid select-sm">
            <b-form-select-option :value="DiscountTypes.value"><small>{{ "KWD" }}</small></b-form-select-option>
            <b-form-select-option :value="DiscountTypes.percentage"><small>{{ "%" }}</small></b-form-select-option>
          </b-form-select>
        </div>

        <div class="form-group">
          <my-date-range-picker :model-data="{startDate: discount.from, endDate: discount.to}"
                                @input="(data)=> updatedDates(data, index) "/>
        </div>
      </template>

    </div>

  </div>
</template>
<script>
import DiscountTypes from "@/config/DiscountTypes";
import MyDateRangePicker from "@/components/Dashboard/Common/MyDateRangePicker.vue";
import moment from 'moment';

const DATE_FORMAT = "YYYY-MM-DDT00:00";

export default {
  components: {MyDateRangePicker},
  computed: {
    DiscountTypes() {
      return DiscountTypes
    }
  },
  props: ["discountsData"],
  data() {
    return {
      discounts: [],
      initialDiscountDates: {startDate: null, endDate: null},
    }
  },
  mounted() {
    this.discounts.forEach( (discount) => {
      if (!discount.from || !discount.to) {
        discount.from = moment().startOf('day').format(DATE_FORMAT);
        discount.to = moment().startOf('day').add(30, 'days').format(DATE_FORMAT);
      }
    })
  },
  methods: {
    updatedDates(data, discountIndex) {
      this.discounts[discountIndex].from = moment(data.startDate).format(DATE_FORMAT);
      this.discounts[discountIndex].to = moment(data.endDate).format(DATE_FORMAT);
    }
  },
  watch: {
    discountsData: {
      deep: true,
      immediate: true,
      handler: function (oldVal, newVal) {
        if (JSON.stringify(oldVal) !== JSON.stringify(newVal))
          this.discounts = this.discountsData;
      }
    },
    discounts: {
      deep: true,
      immediate: true,
      handler: function (oldVal, newVal) {
        if (JSON.stringify(oldVal) !== JSON.stringify(newVal))
          this.$emit('updateDiscounts', this.discounts);
      }
    },
  }

}

</script>

<style scoped>

</style>
