export const presentApiErrorMessage = (component, error) => {
  const {response} = error;
  if (response) {
    const {request, ...errorObject} = response; // take everything but 'request'
    for (const [key, value] of Object.entries(errorObject.data.errors)) {
      component.$error(value[0])
    }
  } else {
    console.log(error);
  }
}

export const submitAndNotify = async (context, action, callback = null, msg = "") => {
  const loader = context.$loading.show();
  if(callback === null) callback = ()=>{};
  action.then((data) => {
    loader.hide();
    if(msg !== false)
      context.$notify({type: 'success', message: 'Done successfully'});
    context.$emit('onSubmit');
    callback(data);
    // context.$refs[formName].resetForm();
  })
    .catch((error) => {
      loader.hide()
      let errorMessage = "";
      if (error.response && error.response.data && error.response.data.errors) {
        return presentApiErrorMessage(context, error);
      } else if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      } else {
        errorMessage = error.message ?? 'An unexpected error occurred.';
      }
      context.$error(errorMessage)
    })
}
