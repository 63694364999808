<template>
  <Form page-title="Edit UserStore" :business-id="businessId"
        :in-list="inList" :model="model" @submit="submit" ref="ColorForm"/>
</template>
<script>
import Form from "./Form";
import {updateUser} from "../../API/UsersAPIs";
export default {
  name: "EditUser",
  components: {Form},
  props: ['inList', 'model', 'businessId'],
  methods: {
    submit(data) {
      let loader = this.$loading.show();
      updateUser(data)
        .then(() => {
          this.$emit('onSubmit');
          this.$notify({message: 'Updated successfully', type: 'success'});
        })
        .catch((error) => {
          let errorMessage = "";
          if (error.response && error.response.data && error.response.data.message)
            errorMessage = error.response.data.message;
          else
            errorMessage = 'An unexpected error occurred.';
          this.$error(errorMessage)
        })
        .finally(() => loader.hide())
    }
  },
  watch:{
    model:{
      deep: true,
      handler: function(val, oldVal) {
        this.model.phone_number = {
          formattedNumber: this.model.phone
        };
      }
    }
  }
}
</script>
<style>
</style>
